var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('responsive-menu',{ref:"menu",attrs:{"bind":_vm.selectedItem,"title":_vm.selectedItemName,"opcoes":_vm.menuOptions},on:{"click:item":function (action) { return _vm.$emit(("click:" + action), _vm.selectedItem); }}},[(_vm.$vuetify.breakpoint.mobile && _vm.selectedItem != null)?_c('v-card',{staticClass:"elevation-0"},[_c('v-card-text',[_c('h3',[_vm._v("Frequência")]),_c('lookup-dominio',{attrs:{"type":_vm.lookup,"loading":_vm.pendingPresenca[_vm.selectedItem.id],"box-style":true,"hide-details":true,"disabled":_vm.selectedItem.flgPresenca !== 0 || _vm.pendingPresenca[_vm.selectedItem.id],"value":_vm.selectedItem.flgPresenca,"hidden-clear":true,"hidden-refresh":true},on:{"input":function (value) { return _vm.atualizarPresenca(_vm.selectedItem, value); }},scopedSlots:_vm._u([(_vm.errorPresenca[_vm.selectedItem.id])?{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"top":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","color":"warning","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.atualizarPresenca(_vm.selectedItem, _vm.errorPresenca[_vm.selectedItem.id])}}},on),[_c('v-icon',[_vm._v("mdi-alert")])],1)]}}],null,false,72603977)},[_c('span',[_vm._v(" Clique para tentar novamente ")])])]},proxy:true}:null],null,true)})],1)],1):_vm._e()],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"fixed-header":"","options":_vm.pageOptions,"server-items-length":_vm.totalElements,"disable-sort":true,"hide-default-header":_vm.$vuetify.breakpoint.mobile,"footer-props":{
    disableItemsPerPage: true,
    itemsPerPageOptions: [_vm.itemsPerPage],
  },"items":_vm.elements,"loading":_vm.loading},on:{"update:options":function($event){_vm.pageOptions=$event},"click:row":_vm.onClickRow,"contextmenu:row":function($event){$event.preventDefault();return _vm.showMenuFromContextMenu.apply(null, arguments)}},scopedSlots:_vm._u([{key:"item.data",fn:function(ref){
  var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(_vm._f("moment")(item.data,'DD/MM/YYYY')))])]}},{key:"item.flgPresenca",fn:function(ref){
  var item = ref.item;
return [_c('lookup-dominio',{attrs:{"type":_vm.lookup,"loading":_vm.pendingPresenca[item.id],"box-style":true,"hide-details":true,"disabled":item.flgPresenca !== 0 || _vm.pendingPresenca[item.id],"value":item.flgPresenca,"hidden-clear":true,"hidden-refresh":true},on:{"input":function (value) { return _vm.atualizarPresenca(item, value); }},scopedSlots:_vm._u([(_vm.errorPresenca[item.id])?{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"top":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","color":"warning","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.atualizarPresenca(item, _vm.errorPresenca[item.id])}}},on),[_c('v-icon',[_vm._v("mdi-alert")])],1)]}}],null,true)},[_c('span',[_vm._v(" Clique para tentar novamente ")])])]},proxy:true}:null],null,true)})]}},{key:"item.edit",fn:function(ref){
  var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){$event.preventDefault();return _vm.showMenu($event, item)}}},[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}},(_vm.$vuetify.breakpoint.mobile)?{key:"body",fn:function(ref){
  var items = ref.items;
  var isSelected = ref.isSelected;
  var select = ref.select;
return [(items.length === 0)?_c('v-card',{staticClass:"elevation-0"},[_c('v-card-text',{staticClass:"text-center"},[_c('p',[_vm._v("Não há dados disponíveis. Utilize o filtro para refazer a pesquisa.")])])],1):_vm._e(),_c('v-list',{staticClass:"pa-0",attrs:{"dense":""}},_vm._l((items),function(item){return _c('v-list-item',{key:item.id,staticClass:"list-item",on:{"click":function($event){return _vm.showMenu($event, item)}}},[_c('v-list-item-avatar',[_vm._v(" #"+_vm._s(item.id)+" ")]),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.nome_idCliente)}}),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm._f("moment")(item.data,'DD/MM/YYYY'))+" - "+_vm._s(item.descricao_idHorario)+" - "+_vm._s(item.descricao_idQuadra)+" ")]),_c('v-list-item-subtitle',[_c('v-chip',{staticClass:"mr-2",attrs:{"color":_vm.classeMarcacao(item.flgPresenca),"input-value":"false","small":""}},[_vm._v(_vm._s(_vm.descricaoMarcacao(item.flgPresenca)))])],1)],1),_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)],1)}),1)]}}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }