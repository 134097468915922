<template>
  <base-view
      @click:add="criar"
      @update:search-values="updateSearch"
      :search-columns="searchColumns">
    <template v-slot:actions:extras>
      <v-btn color="primary"
             @click="gerarFrequencia"
             :block="$vuetify.breakpoint.mobile" :outlined="$vuetify.breakpoint.mobile" :width="'200px'"
             :text="!$vuetify.breakpoint.mobile"> Gerar Frequência Mensal
      </v-btn>
    </template>
    <gerar-frequencia-mensal-dialog ref="gerarFrequenciaDialog" @frequencia:gerada="() => $refs.dataTable.refresh()"/>

    <create-edit-frequencia-dialog :item="item" v-model="editDialogOpened"
                                   @onSave="() => $refs.dataTable.refresh()"/>

    <div>
      <frequencias-data-table ref="dataTable"
                              @click:incluirReposicao="incluirReposicao"
                              @click:remove="remover"
                              @click:estorna="estornar"
                              :searchValues="searchValues"/>
    </div>
  </base-view>

</template>

<script>
import FrequenciasDataTable from "../../components/shared/datatables/FrequenciasDataTable";
import BaseView from "../BaseView";
import {remove, estorna} from "../../api/frequencia";
import CreateEditFrequenciaDialog from "../../components/shared/dialogs/CreateEditFrequenciaDialog";
import {findAll as findAllHorario} from '../../api/horarios';
import {findAll as findAllQuadra} from '../../api/quadras';
import {findAll as findAllCliente} from '../../api/clientes';
import ResponsiveMenu from "../../components/app/ResponsiveMenu";
import GerarFrequenciaMensalDialog from "./components/GerarFrequenciaMensalDialog";
import * as moment from 'moment';
import {ItemDeDominio} from "../../api/itemsDominio";
import {mapActions} from "vuex";

export default {
  name: "index",
  components: {GerarFrequenciaMensalDialog, ResponsiveMenu, CreateEditFrequenciaDialog, BaseView, FrequenciasDataTable},
  data() {
    return {
      item: null,
      editDialogOpened: false,
      searchValues: {},
      searchColumns: [
        {
          name: 'Cliente',
          column: 'idCliente',
          type: 'autocomplete',
          optionsFactory: (search) => findAllCliente(1, {
            nome: search
          })
              .then(r => {
                return r.elements.map(e => ({
                  key: e.id,
                  valor: e.nome
                }))
              })

        },
        {
          group: 'Periodo',
          columns: [
            {
              name: 'De',
              column: 'dataIni',
              type: 'date',
              defaultValue: moment().format('YYYY-MM-DD'),
            },
            {
              name: 'Até',
              column: 'dataFim',
              type: 'date',
              defaultValue: moment().format('YYYY-MM-DD'),
            },
          ]
        },
        {
          name: 'Horario',
          column: 'idHorario',
          type: 'select',
          optionsFactory: () => findAllHorario(1)
              .then(r => r.elements.map(e => ({
                key: e.id,
                valor: e.range
              })))
        },
        {
          name: 'Quadra',
          column: 'idQuadra',
          type: 'select',
          optionsFactory: () => findAllQuadra(1)
              .then(r => r.elements.map(e => ({
                key: e.id,
                valor: e.descricao
              })))
        },
        {
          name: 'Status Presença',
          column: 'flgPresenca',
          type: 'select',
          optionsFactory: () => this.fetch({
            itemDominio: ItemDeDominio.STS_PRESENCA
          })
        },
      ]
    }
  },
  methods: {
    ...mapActions('itemsDominio', ['fetch']),
    gerarFrequencia() {
      this.$refs.gerarFrequenciaDialog.open();
    },
    incluirReposicao(item) {
      this.item = {
        ...item,
        idReposicao: item.id
      };
      this.editDialogOpened = true;
    },
    updateSearch(search) {
      this.searchValues = search;
    },
    criar() {
      this.item = {};
      this.editDialogOpened = true;
    },
    async remover({id}) {
      try {
        await remove(id);
        this.$toast.success(`Frequencia removida com sucesso.`, {
          timeout: 3000
        });
        this.$refs.dataTable.refresh();
      } catch (err) {
        this.$toast.error(`Não foi possível remover a Frequencia.`, {
          timeout: 6000
        })
      }
    },
    async estornar({id}) {
      try {
        await estorna(id);
        this.$toast.success(`Frequencia revertida com sucesso.`, {
          timeout: 3000
        });
        this.$refs.dataTable.refresh();
      } catch (err) {
        this.$toast.error(`Não foi possível reverter a Frequencia.`, {
          timeout: 6000
        })
      }
    }
  },
  mounted() {
  }
}
</script>

<style scoped lang="scss">

::v-deep {
  .v-data-table__wrapper {
    height: calc(100vh - 157px);
  }
}

</style>