var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('search-box',{attrs:{"label":"* Cliente","popup-label":"Selecione um Cliente","id":_vm.value.idCliente,"nome":_vm.value.nome_idCliente,"hidden-clear":true,"value":_vm.cliente,"loader-fn":_vm.loaderClientes,"item-key":"id","item-text":"nome","rules":[
                      this.$validators.notNullOrUndefined
                  ]},on:{"update:id":function($event){return _vm.$set(_vm.value, "idCliente", $event)},"update:nome":function($event){return _vm.$set(_vm.value, "nome_idCliente", $event)}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('p',{staticClass:"ma-2"},[_vm._v(" Nenhum Cliente encontrado ")])]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"dense":"","label":"* Data","type":"date","rules":[
        this.$validators.string.required
    ]},model:{value:(_vm.value.data),callback:function ($$v) {_vm.$set(_vm.value, "data", $$v)},expression:"value.data"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('search-box',{attrs:{"label":"* Horario","popup-label":"Selecione um horario","id":_vm.value.idHorario,"range":_vm.value.descricao_idHorario,"value":_vm.horario,"loader-fn":_vm.loaderHorario,"item-key":"id","item-text":"range","rules":[
              this.$validators.notNullOrUndefined
          ]},on:{"update:id":function($event){return _vm.$set(_vm.value, "idHorario", $event)},"update:range":function($event){return _vm.$set(_vm.value, "descricao_idHorario", $event)},"input":_vm.updateRange},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('p',{staticClass:"ma-2"},[_vm._v(" Nenhum horario encontrado ")])]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('search-box',{attrs:{"label":"* Quadra","popup-label":"Selecione uma quadra","id":_vm.value.idQuadra,"descricao":_vm.value.descricao_idQuadra,"value":_vm.quadra,"loader-fn":_vm.loaderQuadra,"item-key":"id","item-text":"descricao","rules":[
              this.$validators.notNullOrUndefined
          ]},on:{"update:id":function($event){return _vm.$set(_vm.value, "idQuadra", $event)},"update:descricao":function($event){return _vm.$set(_vm.value, "descricao_idQuadra", $event)}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('p',{staticClass:"ma-2"},[_vm._v(" Nenhuma quadra encontrada ")])]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('lookup-dominio',{attrs:{"label":"* Status","type":_vm.lookups.status,"hidden-clear":true,"rules":[
                      this.$validators.notNullOrUndefined
                  ]},model:{value:(_vm.value.flgPresenca),callback:function ($$v) {_vm.$set(_vm.value, "flgPresenca", $$v)},expression:"value.flgPresenca"}})],1),_c('v-col',{attrs:{"cols":"12"}},[(_vm.$vuetify.breakpoint.mobile)?_c('v-textarea',{attrs:{"dense":"","rows":"3","label":"* Observação","rules":[
                      this.$validators.string.required
                  ]},model:{value:(_vm.value.obs),callback:function ($$v) {_vm.$set(_vm.value, "obs", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.obs"}}):_c('v-text-field',{attrs:{"label":"* Observação","dense":"","rules":[
                      this.$validators.string.required
                  ]},model:{value:(_vm.value.obs),callback:function ($$v) {_vm.$set(_vm.value, "obs", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.obs"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }