import {marcarPresenca} from "../../../../api/frequencia";

export default {
    state: {
        pending: {},
        error: {}
    },
    mutations: {
        INICIAR_ATUALIZACAO(state, id) {
            state.pending = {
                ...state.pending,
                [id]: true
            };
            state.error = {
                ...state.error,
                [id]: null
            }
        },
        CONCLUIR_ATUALIZACAO(state, id) {
            state.pending = {
                ...state.pending,
                [id]: false
            };
        },
        MARCAR_ATUALIZACAO_COM_ERRO(state, {id, presenca}) {
            console.log(id, presenca);
            state.error = {
                ...state.error,
                [id]: presenca
            }
        }
    },

    actions: {
        async atualizar({context, commit}, {id, presenca}) {
            commit('INICIAR_ATUALIZACAO', id);
            try {
                await marcarPresenca(id, presenca);
            } catch (err) {
                commit('MARCAR_ATUALIZACAO_COM_ERRO', {id, presenca});
            } finally {
                commit('CONCLUIR_ATUALIZACAO', id)
            }

        }
    }
}