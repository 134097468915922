<template>
  <div>

    <responsive-menu ref="menu"
                     :bind="selectedItem"
                     :title="selectedItemName"
                     :opcoes="menuOptions"
                     @click:item="action => $emit(`click:${action}`, selectedItem)">
      <v-card class="elevation-0" v-if="$vuetify.breakpoint.mobile && selectedItem != null">
        <v-card-text>
          <h3>Frequência</h3>
          <lookup-dominio :type="lookup"
                          :loading="pendingPresenca[selectedItem.id]"
                          :box-style="true"
                          :hide-details="true"
                          :disabled="selectedItem.flgPresenca !== 0 || pendingPresenca[selectedItem.id]"
                          @input="value => atualizarPresenca(selectedItem, value)"
                          :value="selectedItem.flgPresenca"
                          :hidden-clear="true"
                          :hidden-refresh="true">
            <template v-slot:append v-if="errorPresenca[selectedItem.id]">
              <v-tooltip top left>
                <template v-slot:activator="{on}">
                  <v-btn @click.stop="atualizarPresenca(selectedItem, errorPresenca[selectedItem.id])"
                         v-on="on" x-small color="warning" icon>
                    <v-icon>mdi-alert</v-icon>
                  </v-btn>
                </template>
                <span>
                Clique para tentar novamente
              </span>
              </v-tooltip>
            </template>
          </lookup-dominio>
        </v-card-text>
      </v-card>

    </responsive-menu>
    <v-data-table :headers="headers"
                  fixed-header
                  :options.sync="pageOptions"
                  :server-items-length="totalElements"
                  :disable-sort="true"
                  :hide-default-header="$vuetify.breakpoint.mobile"
                  @click:row="onClickRow"
                  @contextmenu:row.prevent="showMenuFromContextMenu"
                  :footer-props="{
      disableItemsPerPage: true,
      itemsPerPageOptions: [itemsPerPage],
    }"
                  :items="elements" :loading="loading">
      <template v-slot:item.data="{item}">
        <strong>{{ item.data | moment('DD/MM/YYYY') }}</strong>
      </template>
      <template v-slot:item.flgPresenca="{item}">
        <lookup-dominio :type="lookup"
                        :loading="pendingPresenca[item.id]"
                        :box-style="true"
                        :hide-details="true"
                        :disabled="item.flgPresenca !== 0 || pendingPresenca[item.id]"
                        @input="value => atualizarPresenca(item, value)"
                        :value="item.flgPresenca"
                        :hidden-clear="true"
                        :hidden-refresh="true">
          <template v-slot:append v-if="errorPresenca[item.id]">
            <v-tooltip top left>
              <template v-slot:activator="{on}">
                <v-btn @click.stop="atualizarPresenca(item, errorPresenca[item.id])"
                       v-on="on" x-small color="warning" icon>
                  <v-icon>mdi-alert</v-icon>
                </v-btn>
              </template>
              <span>
                Clique para tentar novamente
              </span>
            </v-tooltip>
          </template>
        </lookup-dominio>
      </template>
      <template v-slot:item.edit="{item}">
        <v-btn @click.prevent="showMenu($event, item)" small icon>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>


      <template v-slot:body="{items, isSelected, select}" v-if="$vuetify.breakpoint.mobile">
        <v-card v-if="items.length === 0" class="elevation-0">
          <v-card-text class="text-center">
            <p>Não há dados disponíveis. Utilize o filtro para refazer a pesquisa.</p>
          </v-card-text>
        </v-card>
        <v-list dense class="pa-0">
          <v-list-item @click="showMenu($event, item)" v-for="item of items" :key="item.id"
                       class="list-item">
            <v-list-item-avatar>
              #{{ item.id }}
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-text="item.nome_idCliente"/>
              <v-list-item-subtitle>
                {{item.data | moment('DD/MM/YYYY')}} - {{item.descricao_idHorario}} - {{item.descricao_idQuadra}}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <v-chip :color="classeMarcacao(item.flgPresenca)" class="mr-2" input-value="false" small>{{descricaoMarcacao(item.flgPresenca)}}</v-chip>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>mdi-dots-vertical</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </template>
    </v-data-table>
  </div>
</template>

<script>

import {findAll} from '../../../../api/frequencia';
import ResponsiveMenu from "../../../app/ResponsiveMenu";
import LookupDominio from "../../common/LookupDominio";
import {ItemDeDominio} from "../../../../api/itemsDominio";
import {mapActions, mapState, mapGetters} from "vuex";
import dataTableStore from './store';

/**
 * @emits click:edit
 * @emit click:remove
 */
export default {
  name: "FrequenciasDataTable",
  components: {LookupDominio, ResponsiveMenu},
  props: {
    searchValues: {
      type: Object,
    },
  },
  data() {
    return {
      lookup: ItemDeDominio.STS_PRESENCA,
      selectedItem: null,
      menuOptions: [
        {
          action: 'incluirReposicao',
          text: 'Incluir Reposição',
          icon: 'mdi-autorenew',
          hiddenWhen(item) {
            return item?.flgPresenca === 0;
          },
          disabledWhen(item) {
            return item?.flgPresenca !== 2;
          },
        },
        {
          action: 'remove',
          text: 'Remover',
          icon: 'mdi-delete',
          color: 'error',
          hiddenWhen(item) {
            return item?.flgPresenca !== 0 || item?.nivel !== 'ADM';
          },
        },
        {
          action: 'estorna',
          text: 'Reverter marcação',
          icon: 'mdi-file-undo',
          color: 'orange',
          hiddenWhen(item) {
            return item?.flgPresenca === 0;
          },
        },
      ],
      menu: {
        positionX: 0,
        positionY: 0,
        show: false
      },
      loading: false,
      error: null,
      pageOptions: {
        page: 1
      },
      itemsPerPage: 0,
      totalElements: 0,
      elements: [],
      headers: [
        {
          text: 'Data',
          value: 'data',
          align: 'start',
          width: 140
        },
        {
          text: 'Aluno',
          value: 'nome_idCliente',
          align: 'start'
        },        
        {
          text: 'Dia',
          value: 'diaSemana',
          align: 'start',
          width: 140
        },
        {
          text: 'Horário',
          value: 'descricao_idHorario',
          align: 'start',
          width: 140
        },
        {
          text: 'Quadra',
          value: 'descricao_idQuadra',
          align: 'start',
          width: 140
        },
        {
          text: 'Aula Reposição',
          value: 'idReposicao',
          align: 'start',
          width: 120
        },
        {
          text: 'Status',
          value: 'flgPresenca',
          align: 'start',
          width: 200
        },
        {
          text: 'Obs',
          value: 'obs',
          align: 'start',
          width: 120
        },
        {
          text: 'Menu',
          width: 30,
          value: 'edit',
          align: 'end'
        }
      ],
    }
  },
  computed: {
    ...mapGetters('auth',['user','isAuthenticated','nivelUsuarioLogado']),
    ...mapState('FrequenciaDataTable', {
      'pendingPresenca': 'pending',
      'errorPresenca': 'error'
    }),
    selectedItemName() {
      let descricao = this.selectedItem?.descricao_idHorario+' - '+this.selectedItem?.descricao_idQuadra +' ('+ this.selectedItem?.nome_idCliente +')'
      return descricao;
    }
  },
  watch: {
    searchValues: {
      deep: true,
      handler() {
        this.refresh();
      }
    },
    'pageOptions.page': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) this.refresh(newValue);
      }
    }
  },
  methods: {
    ...mapActions('FrequenciaDataTable', ['atualizar']),
    async atualizarPresenca(item, presenca) {
      try {
        await this.atualizar({
          id: item.id,
          presenca
        });
        item.flgPresenca = presenca;
      } catch (err) {
        this.$toast.error(`Não foi possível marcar a situação de presença para o aluno ${item.nome_idCliente}`, {
          timeout: 3000
        })
      }
    },
    descricaoMarcacao(item) {
      return item === 1 ? "Presente" : (item === 2 ? "Ausente" : "Não Informado")
    },
    classeMarcacao(item) {
      return item === 1 ? "success" : (item === 2 ? "error" : "")
    },
    showMenuFromContextMenu(...contextEvent) {
      const event = contextEvent[0];
      const {item} = contextEvent[1];
      this.showMenu(event, item);
    },
    showMenu(event, item) {
      this.selectedItem = !item.item ? item : item.item;
      this.selectedItem.nivel = this.nivelUsuarioLogado;
      this.$refs.menu.open(event);
    },
    onClickRow(row) {
      this.$emit('click:row', row);
    },
    async refresh() {
      try {
        this.error = null;
        this.loading = true;
        const page = await findAll(this.pageOptions.page, {
          ...this.searchValues
        });

        this.itemsPerPage = page.elementsPerPage;
        this.totalElements = page.totalElements;
        this.elements = page.elements;
        this.pageOptions.page = page.currentPage;

      } catch (err) {
        this.error = err;
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.$store.registerModule('FrequenciaDataTable', {
      namespaced: true,
      ...dataTableStore
    })
    this.pageOptions.page = 1;
    this.refresh();
  },

  destroyed() {
    this.$store.unregisterModule('FrequenciaDataTable')
  }
}
</script>

<style scoped lang="scss">
.list-item {
  &::v-deep {
    .v-list-item__content {
      max-width: calc(100vw - 152px);
    }
  }
}

.list-item:not(:last-child) {
  border-bottom: 1px solid #dedede
}

</style>