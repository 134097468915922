<template>
  <v-dialog :fullscreen="$vuetify.breakpoint.mobile"
            :value="value"
            max-width="500px"
            persistent>
    <v-card class="d-flex flex-column">
      <v-card-title>
        Selecione um mês de referência
        <v-spacer/>
        <v-btn icon @click="value = false" v-show="!gerandoFrencquencia">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider/>
      <v-card-text class="py-4 flex-grow-1" v-if="value">
        <v-progress-linear v-if="gerandoFrencquencia" indeterminate color="primary"></v-progress-linear>
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col cols="6">
              <v-select v-model="ano" :items="anos" placeholder="Ano" :rules="[
              $validators.number.required
          ]"/>
            </v-col>
            <v-col cols="6">

              <v-select v-model="mes" :items="meses" placeholder="Mês" :rules="[
              $validators.number.required
          ]"/>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-spacer/>
        <v-btn outlined color="primary" @click="value = false" v-show="!gerandoFrencquencia">Cancelar</v-btn>
        <v-btn color="primary" @click="gerar" :disabled="!valid" v-show="!gerandoFrencquencia">Gerar Frequência</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {gerarFrequenciaMensal} from "../../../api/frequencia";
import {ApiError} from "../../../api/core";
import * as moment from 'moment';

export default {
  name: "GerarFrequenciaMensalDialog",
  data () {
    return {
      gerandoFrencquencia: false,
      valid: true,
      value: false,
      ano: null,
      mes: null,
      meses: [1,2,3,4,5,6,7,8,9,10,11,12]
    }
  },
  computed: {
    anos() {
      let atual = moment().format('YYYY');
      return [
          parseInt(atual) + 1,
          atual,
          atual - 1,
      ]
    }
  },
  methods: {
    open() {
      this.value = true;
    },
    close() {
      this.value = false;
    },
    async gerar() {
      if(!this.$refs.form.validate()) return;
      this.gerandoFrencquencia = true
      try {
        const parametroMesAno = moment(`${this.mes}/${this.ano}`,'D/YYYY').format('DD/YYYY');
        await gerarFrequenciaMensal(parametroMesAno);
        this.$emit('frequencia:gerada')
        this.gerandoFrencquencia = false
        this.value = false;
        this.$toast.success(`Frequência gerada com sucesso.`, {
          timeout: 3000
        })

    } catch (err) {
        if (err instanceof ApiError) {
          this.$toast.error(err.message, {
            timeout: 6000
          })
        } else {
          this.$toast.error(`Não foi possível gerar a frequência`, {
            timeout: 6000
          })
        }
      }
    }
  }
}
</script>

<style scoped>

</style>