<template>
<v-row>
  <v-col cols="12" md="6">
    <search-box
        label="* Cliente"
        popup-label="Selecione um Cliente"
        :id.sync="value.idCliente"
        :nome.sync="value.nome_idCliente"
        :hidden-clear="true"
        :value="cliente"
        :loader-fn="loaderClientes"
        item-key="id"
        item-text="nome"
        :rules="[
                      this.$validators.notNullOrUndefined
                  ]">
      <template v-slot:empty>
        <p class="ma-2">
          Nenhum Cliente encontrado
        </p>
      </template>

    </search-box>
  </v-col>
  <v-col cols="12" sm="6">
    <v-text-field dense label="* Data" type="date"
                  v-model="value.data"
                  :rules="[
        this.$validators.string.required
    ]"/>
  </v-col>
  <v-col cols="12" md="6">
    <search-box
        label="* Horario"
        popup-label="Selecione um horario"
        :id.sync="value.idHorario"
        :range.sync="value.descricao_idHorario"
        @input="updateRange"
        :value="horario"
        :loader-fn="loaderHorario"
        item-key="id"
        item-text="range"
        :rules="[
              this.$validators.notNullOrUndefined
          ]">
      <template v-slot:empty>
        <p class="ma-2">
          Nenhum horario encontrado
        </p>
      </template>

    </search-box>
  </v-col>
  <v-col cols="12" md="6">
    <search-box
        label="* Quadra"
        popup-label="Selecione uma quadra"
        :id.sync="value.idQuadra"
        :descricao.sync="value.descricao_idQuadra"
        :value="quadra"
        :loader-fn="loaderQuadra"
        item-key="id"
        item-text="descricao"
        :rules="[
              this.$validators.notNullOrUndefined
          ]">
      <template v-slot:empty>
        <p class="ma-2">
          Nenhuma quadra encontrada
        </p>
      </template>

    </search-box>
  </v-col>
  <v-col cols="12">
    <lookup-dominio label="* Status"
                    v-model="value.flgPresenca"
                    :type="lookups.status"
                    :hidden-clear="true"
                    :rules="[
                      this.$validators.notNullOrUndefined
                  ]"/>
  </v-col>
  <v-col cols="12">
    <v-textarea v-if="$vuetify.breakpoint.mobile"
                dense
                rows="3"
                label="* Observação"
                :rules="[
                      this.$validators.string.required
                  ]"
                v-model.trim="value.obs"/>
    <v-text-field v-else label="* Observação"
                  dense
                  :rules="[
                      this.$validators.string.required
                  ]"
                  v-model.trim="value.obs"/>
  </v-col>
</v-row>
</template>

<script>
import {ItemDeDominio} from "../../../api/itemsDominio";
import {findAll as findAllQuadras} from "../../../api/quadras";
import {findAll as findAllAulas} from "../../../api/aulas";
import {findAll as findAllProfessores} from "../../../api/professores";
import {findAll as findAllHorarios} from "../../../api/horarios";
import SearchBox from "../common/SearchBox";
import LookupDominio from "../common/LookupDominio";
import {findAll as findAllClientes} from "../../../api/clientes";

export default {
  name: "FrequenciaForm",
  components: {LookupDominio, SearchBox},
  props: {
    value: {
      type: Object
    }
  },
  data () {
    return {
      lookups: {
        status: ItemDeDominio.STS_PRESENCA
      },
      loaderQuadra(page, search) {
        return findAllQuadras(page, {
          descricao: search
        })
      },

      loaderHorario(page, search) {
        return findAllHorarios(page, {
          horaInicio: search
        })
      }
    }
  },
  computed: {

    cliente() {
      if (this.value.idCliente == null) return null;
      return {
        id: this.value.idCliente,
        nome: this.value.nome_idCliente
      }
    },
    quadra() {
      if(this.value.idQuadra == null) return null;
      return {
        id: this.value.idQuadra,
        descricao: this.value.descricao_idQuadra
      }
    },
    horario() {
      if(this.value.idHorario == null) return null;
      return {
        id: this.value.idHorario,
        range: this.value.descricao_idHorario
      }
    }
  },
  methods: {
    updateRange(range) {
      this.value.hora_inicio_idHorario = range ? range.horaInicio : null;
    },

    loaderClientes(page, search) {
      return findAllClientes(page, {
        nome: search
      })
    },
  },
  mounted() {
    this.value.obs = "";
    this.value.flgPresenca = null;
  }
}
</script>

<style scoped>

</style>